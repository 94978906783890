@font-face {
    font-family: 'MavenPro-Regular';
    font-style: normal;
    font-weight: 'bold';
    src: url('../fonts/MavenPro-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'MavenPro-Bold';
    font-style: normal;
    font-weight: 700 !important;
    src: url('../fonts/MavenPro-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'MavenPro-SemiBold';
    font-style: normal;
    font-weight: 500 !important;
    src: url('../fonts/MavenPro-SemiBold.ttf') format('opentype');
}

body {
    margin: 0;
    background: linear-gradient(104.04deg, #ffffff 0%, #fff3eb 100%);
    font-family: 'MavenPro-Regular', 'Open Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.textCenter {
    text-align: center;
}

.flexRow {
    display: flex;
    justify-content: space-between;
}
.flexRowAround{
    display: flex;
    justify-content: space-around;
}
.flexColumn {
    display: flex;
    flex-direction: column;
}

.stretch {
    width: 100%;
    height: 100%;
}

.centerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.glass {
    background: linear-gradient(147.7deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.25) 100.77%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    box-shadow: 16px 16px 32px rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(32px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 18px;
}

.glass-black {
    background: linear-gradient(
        147.7deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.25) 100.77%
    );
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    box-shadow: 16px 16px 32px rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(32px);
    border-radius: 16px;
}

.mp-bold {
    font-family: 'MavenPro-Bold', 'Open Sans' !important;
}

.mp-semi {
    font-family: 'MavenPro-SemiBold', 'Open Sans' !important;
}

.text10 {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
}

.text12 {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
}

.text14 {
    font-size: 14px;
    line-height: 150%;
}

.text16 {
    font-size: 16px;
    line-height: 150%;
}

.text20 {
    font-size: 20px;
    line-height: 175%;
}

.text24 {
    font-size: 24px;
    line-height: 150%;
}
.text26 {
  font-size: 26px;
  line-height: 150%;
}
.text32 {
    font-size: 32px;
    line-height: 150%;
    color: #fd6a02;
    font-weight: 700;
}

.text40 {
    font-weight: bold;
    font-size: 40px;
    line-height: 150%;
}

.text48 {
    font-weight: bold;
    font-size: 48px;
    line-height: 150%;
}

.text56 {
    font-size: 56px;
    line-height: 150%;
    color: #fd6a02;
    font-style: normal;
    font-family: 'MavenPro-Bold', 'Open-Sans';
    margin-bottom: 8px;
}

.primary {
    color: #fd6a02;
}

.secondary {
    color: #030201;
}

.lightGrey {
    color: #fff3eb;
}
.darkGrey {
    color: #2c2e30;
}

.nuetralGrey {
    color: #61686c;
}

.white {
    color: #ffffff;
}

.link {
    text-decoration: none;
    cursor: pointer;
}

w400 {
    font-weight: 400 !important;
}

.orangeBtn {
    background: linear-gradient(90deg, #fd6a02 10%, #ff5500 100%);
    border-radius: 32px !important;
    color: #fff3eb !important;
}

.zoomImg {
    -webkit-animation: zoomout 3s ease-in ;
    animation: zoomout 3s ease-in ;
    transition: all 1s ease-in;
    height:100vh;
    width:100%;
}
  
  @keyframes zoomout {
    0% {transform: scale(1.75) translateX(-20%);}
    50% {transform: scale(1);}
   
  }
 

.zoom-max {
    /* background-color: aqua !important; */
    position: relative;
    left: 0;
    right: 0;
    width: 100vw;
}
.red, .green, .blue {
    position: absolute;
    width: 100px;
    color: white;
    line-height: 100px;
    text-align: center;
  }
  
  .red {
    z-index: 2;
    
   
  }
  
  .green {
 
    z-index: 1;
 
  }
  
  .blue {
    top: 100px;
    /* left: 100px;xs */
   
  }
  
  #parallax-container {
    position: relative;
    display: block;
    height: 879px;
    /* background: #1b1a1b; */
  
  }
  .yellowside {
    background-position: right !important;
    background-repeat: no-repeat;
    /* background-color: #FBB040; */
    background-blend-mode: screen;
    z-index: 1;
  
  }
  .logo {
    background-repeat: no-repeat;
    
  }
  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #FFF3EB inset;}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #FFF3EB inset;
} 




.mySlides {
    opacity: 1;
    transform: none;
  }
  /* Fading animation */
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1s;
    animation-name: fade;
    animation-duration: 1s;
    transition: opacity 1s;
  }
  .fadeRight {
    -webkit-animation-name: faderight;
    -webkit-animation-duration: 1s;
    animation-name: faderight;
    animation-duration: 1s;
    transition: opacity 1s;
  }

  @-webkit-keyframes faderight {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes faderight {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  .fadeLeftText {
    -webkit-animation-name: fadein;
    -webkit-animation-duration: 350ms;
    animation-name: fadein;
    animation-duration: 350ms;
    transition-delay: 350ms, 350ms;
    transition: opacity 350ms, transform 350ms ease-out, visibility 0s 350ms,
      -webkit-transform 350ms ease-out;
  }
  
  .fadeRightText {
    -webkit-animation-name: fadeout;
    -webkit-animation-duration: 350ms;
    animation-name: fadeout;
    animation-duration: 350ms;
    transition-delay: 350ms, 350ms;
    transition: opacity 350ms, transform 350ms ease-out, visibility 0s 350ms,
      -webkit-transform 350ms ease-out;
  }@keyframes fadein {
    from {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0px, 0, 0);
    }
    to {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(10px, 0, 0);
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0px, 0, 0);
    }
    to {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-10px, 0, 0);
    }
  }